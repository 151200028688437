export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Catalog",
    path: '/catalog',
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Cart",
    path: "/dashboard/cart",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
];
