import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllAdmins,
  getAllEnrolledStudents,
  getAllInstructors,
  getAllUsers,
} from "../../../../../services/operations/profileAPI";
import UserTable from "./UserTable";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const { token } = useSelector((state) => state.auth);
  const [allUsers, setAllUsers] = useState([]);
  const [enrolledUsers, setAllEnrolledUsers] = useState([]);
  const [allInstructors, setAllInstructors] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await getAllUsers(token);
        setAllUsers(response);
      } catch (error) {
        console.error("Error fetching all users:", error);
      }
    };

    const fetchAllInstructors = async () => {
      try {
        const response = await getAllInstructors(token);
        setAllInstructors(response);
      } catch (error) {
        console.error("Error fetching all instructors:", error);
      }
    };

    const fetchAllAdmins = async () => {
      try {
        const response = await getAllAdmins(token);
        setAllAdmins(response);
      } catch (error) {
        console.error("Error fetching all admins:", error);
      }
    };

    const fetchAllEnrolledStudents = async () => {
      try {
        const response = await getAllEnrolledStudents(token);
        setAllEnrolledUsers(response);
      } catch (error) {
        console.error("Error fetching all enrolled students:", error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchAllUsers();
      await fetchAllInstructors();
      await fetchAllAdmins();
      await fetchAllEnrolledStudents();
      setLoading(false);
    };

    fetchData();
  }, [token]);

  return (
    <Fragment>
      <div className="text-white">AdminDashboard</div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Fragment>
          <div className="flex flex-wrap">
            <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
              Enrolled Users {enrolledUsers.length}
            </div>
            <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
              Instructors {allInstructors.length}
            </div>
            <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
              Admins {allAdmins.length}
            </div>
            <Link to="/courses/all">
            <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
              Courses 10
            </div>
            </Link>
            <Link to="/dashboard/categoryList">
              <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
                Categories 4
              </div>
            </Link>
            <Link to="/contacts/all">
              <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
                Contact Forms
              </div>
            </Link>
            <Link to="/mentors/applied">
              <div className="min-h-[10vw] w-fit bg-blue-100 p-5 text-center align-center justify-center text-white rounded text-2xl m-[3vw]">
                Applied Mentors
              </div>
            </Link>
          </div>
          <h2 className="text-center text-white">Enrolled Users</h2>
          <hr className="text-center w-56 m-auto text-yellow-25"/>
          <UserTable users={enrolledUsers} />
          <h2 className="text-center text-white mt-2"> Instructors</h2>
          <hr className="text-center w-56 m-auto text-yellow-25"/>
          <UserTable users={allInstructors} />
          <h2 className="text-center text-white mt-2">Admins</h2>
          <hr className="text-center w-56 m-auto text-yellow-25"/>
          <UserTable users={allAdmins} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default AdminDashboard;
