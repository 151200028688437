import React, { useEffect, useState } from "react";
import { getAllEnrolledStudents, getAllInstructors } from "../../../services/operations/profileAPI";
import { useSelector } from "react-redux";

const Stats = [
  { count: "50+", label: "Active Students" },
  { count: "2+", label: "Mentors" },
  { count: "10+", label: "Courses" },
  { count: "2+", label: "Awards" },
];

const StatsComponenet = () => {

  // const [enrolledStudents,setEnrolledStudents]=useState([])
  // const [mentors,setMentors]=useState([])
  // const [courses,setCourses]=useState([])
  // const [loading, setLoading] = useState(true);
  // const { token } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   const fetchAllInstructors = async () => {
  //     try {
  //       const response = await getAllInstructors(token);
  //       setMentors(response);
  //     } catch (error) {
  //       console.error("Error fetching all instructors:", error);
  //     }
  //   };

  //   const fetchAllEnrolledStudents = async () => {
  //     try {
  //       const response = await getAllEnrolledStudents(token);
  //       setEnrolledStudents(response);
  //     } catch (error) {
  //       console.error("Error fetching all enrolled students:", error);
  //     }
  //   };

  //   const fetchData = async () => {
  //     setLoading(true);
  //     await fetchAllInstructors();
  //     await fetchAllEnrolledStudents();
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [token]);


  return (
    <div className="bg-richblack-700">
      {/* Stats */}
      <div className="flex flex-col gap-10 justify-between w-11/12 max-w-maxContent text-white mx-auto ">
        <div className="grid grid-cols-2 md:grid-cols-4 text-center">
          {Stats.map((data, index) => {
            return (
              <div className="flex flex-col py-10" key={index}>
                <h1 className="text-[30px] font-bold text-richblack-5">
                  {data.count}
                </h1>
                <h2 className="font-semibold text-[16px] text-richblack-500">
                  {data.label}
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatsComponenet;