import { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import IconBtn from "../../common/IconBtn";
import { generateCertificate } from "../../../services/operations/courseDetailsAPI";

export default function VideoDetailsSidebar({ setReviewModal }) {
  const [activeStatus, setActiveStatus] = useState("");
  const [videoBarActive, setVideoBarActive] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [courseCompleted, setCourseCompleted] = useState(false);
  // const [Certificate, setCertificate] = useState(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let count = 0;
  const navigate = useNavigate();
  const location = useLocation();
  const { sectionId, subSectionId } = useParams();
  const {
    courseSectionData,
    courseEntireData,
    totalNoOfLectures,
    completedLectures,
  } = useSelector((state) => state.viewCourse);
  const { email } = useSelector((state) => state.profile.user);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    (() => {
      if (!courseSectionData.length) return;
      // if (localStorage.getItem("certificate")) {
      //   setCertificate(localStorage.getItem("certificate"));
      // }
      courseSectionData.forEach(
        (course) => (count += course.subSection?.length || 0)
      );
      // console.log("count is ", count);
      if (count === completedLectures.length) {
        setCourseCompleted(true);
      }
      const currentSectionIndx = courseSectionData.findIndex(
        (data) => data._id === sectionId
      );
      const currentSubSectionIndx = courseSectionData?.[
        currentSectionIndx
      ]?.subSection.findIndex((data) => data._id === subSectionId);
      const activeSubSectionId =
        courseSectionData[currentSectionIndx]?.subSection?.[
          currentSubSectionIndx
        ]?._id;
      setActiveStatus(courseSectionData?.[currentSectionIndx]?._id);
      setVideoBarActive(activeSubSectionId);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSectionData, courseEntireData, location.pathname]);
  const courseName = courseEntireData.courseName;
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  let Certificate = localStorage.getItem("certificate");
  const handleGeneratingCertificate = async () => {
    // setCertificate(localStorage.getItem("certificate"));
    if (Certificate) return;
    const response = await generateCertificate({ courseName, email }, token);
    // console.log("response in sidebar", response);

    // Assuming the API returns the file content in a field named "fileContent"
    const filePath = `${BASE_URL}/certificates/${response.data}`;
    // console.log("filePath", filePath);

    if (filePath) {
      localStorage.setItem("certificate", filePath);
    }
  };

  const previewCertificate = () => {
    const certificatePDF = localStorage.getItem("certificate");
    if (certificatePDF) {
      const blob = new Blob([certificatePDF], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = certificatePDF;
      anchor.target = "_blank"; // Open in a new tab
      anchor.download = "Certificate.pdf"; // Set desired file name for download

      document.body.appendChild(anchor); // Append anchor to body
      anchor.click();

      // Clean up by removing the anchor from the body
      document.body.removeChild(anchor);

      // Clean up by revoking the URL
      URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      {showSidebar ? (
        <div className="flex h-[calc(100vh-3.5rem)] w-[320px] max-w-[350px] flex-col border-r-[1px] border-r-richblack-700 bg-richblack-800">
          <div className="mx-5 flex flex-col items-start justify-between gap-2 gap-y-4 border-b border-richblack-600 py-5 text-lg font-bold text-richblack-25">
            <div className="flex w-full items-center justify-between">
              <div
                onClick={toggleSidebar}
                className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-richblack-100 p-1 text-richblack-700 hover:scale-90"
                title="back"
              >
                <IoIosArrowBack size={30} />
              </div>
              <IconBtn
                text="Add Review"
                customClasses="ml-auto"
                onclick={() => setReviewModal(true)}
              />
            </div>
            <div className="flex flex-col">
              <p>{courseEntireData?.courseName}</p>
              <p className="text-sm font-semibold text-richblack-500">
                {completedLectures?.length} / {totalNoOfLectures}
              </p>
            </div>
          </div>

          <div className="h-[calc(100vh - 5rem)] overflow-y-auto">
            {courseSectionData.map((course, index) => (
              <div
                className="mt-2 cursor-pointer text-sm text-richblack-5"
                onClick={() => setActiveStatus(course?._id)}
                key={index}
              >
                {/* Section */}
                <div className="flex flex-row justify-between bg-richblack-600 px-5 py-4">
                  <div className="w-[70%] font-semibold">
                    {course?.sectionName}
                  </div>
                  <div className="flex items-center gap-3">
                    <span
                      className={`${
                        activeStatus === course?.sectionName
                          ? "rotate-0"
                          : "rotate-180"
                      } transition-all duration-500`}
                    >
                      <BsChevronDown />
                    </span>
                  </div>
                </div>

                {/* Sub Sections */}
                {activeStatus === course?._id && (
                  <div className="transition-[height] duration-500 ease-in-out">
                    {course.subSection.map((topic, i) => (
                      <div
                        className={`flex gap-3  px-5 py-2 ${
                          videoBarActive === topic._id
                            ? "bg-yellow-200 font-semibold text-richblack-800"
                            : "hover:bg-richblack-900"
                        } `}
                        key={i}
                        onClick={() => {
                          navigate(
                            `/view-course/${courseEntireData?._id}/section/${course?._id}/sub-section/${topic?._id}`
                          );
                          setVideoBarActive(topic._id);
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={completedLectures.includes(topic?._id)}
                          onChange={() => {}}
                        />
                        {topic.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          {courseCompleted && (
            <div className="flex items-center justify-between rounded-t-lg mt-5 bg-richblack-700 p-5">
              <p
                onClick={previewCertificate}
                className="text-xl font-semibold text-richblack-5 cursor-pointer"
              >
                View Certificate
              </p>
              <button onClick={handleGeneratingCertificate}>
                <FaFileDownload className="text-2xl text-yellow-100" />
              </button>
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={toggleSidebar}
          className="flex h-[35px] w-[35px] z-100 items-center justify-center rounded-full bg-richblack-100 p-1 text-richblack-700 hover:scale-90 relative top-[50%] left-3"
          title="forward"
        >
          <IoIosArrowForward size={30} />
        </div>
      )}
    </>
  );
}
