import React from "react";
import HighlightText from "../../../components/core/HomePage/HighlightText";
import CTAButton from "../../../components/core/HomePage/Button";

const LearningGridArray = [
  {
    order: -1,
    heading: "World-Class Learning for",
    highlightText: "Anyone, Anywhere",
    description:
      "Study-Hub understand that life can be hectic, and traditional education may not always fit into your schedule. That's why our platform is designed for flexibility. Learn at your own pace, on your own terms, and from the comfort of your home or office. Whether you're a full-time professional, a busy parent, or a lifelong learner, we have something for everyone.",
    BtnText: "Learn More",
    BtnLink: "/",
  },
  {
    order: 1,
    heading: "Curriculum Based on Experienced Seniors",
    description:
      "Save time and money! The Belajar curriculum is made to be easier to understand and in line with industry needs.",
  },
  {
    order: 2,
    heading: "Innovative Learning Approaches",
    description:
      "StudyHub provides the experienced and qualified mentors to make our motive success",
  },
  {
    order: 3,
    heading: "Certification for Your Career Advancement",
    description:
      "StudyHub is your gateway to industry-recognized certifications.we offer certification programs that validate your skills and enhance your career prospects.",
  },
  {
    order: 4,
    heading: `Automated Rating for Seamless Learning`,
    description:
      "Receive instant feedback on your assignments and assessments, allowing you to track your progress and make improvements along the way.",
  },
  {
    order: 5,
    heading: "Join the Future of Learning",
    description:
      "Join StudyHub.tech today and discover a world of possibilities.Let's shape the future of learning together.",
  },
];

const LearningGrid = () => {
  return (
    <div className="grid mx-auto w-[350px] xl:w-fit grid-cols-1 xl:grid-cols-4 mb-12">
      {LearningGridArray.map((card, i) => {
        return (
          <div
            key={i}
            className={`${i === 0 && "xl:col-span-2 xl:h-[294px]"}  ${
              card.order % 2 === 1
                ? "bg-richblack-700 h-[294px]"
                : card.order % 2 === 0
                ? "bg-richblack-800 h-[294px]"
                : "bg-transparent"
            } ${card.order === 3 && "xl:col-start-2"}  `}
          >
            {card.order < 0 ? (
              <div className="xl:w-[90%] flex flex-col gap-3 pb-10 xl:pb-0">
                <div className="text-4xl font-semibold ">
                  {card.heading}
                  <HighlightText text={card.highlightText} />
                </div>
                <p className="text-richblack-300 font-medium">
                  {card.description}
                </p>

                <div className="w-fit mt-2">
                  <CTAButton active={true} linkto={card.BtnLink}>
                    {card.BtnText}
                  </CTAButton>
                </div>
              </div>
            ) : (
              <div className="p-8 flex flex-col gap-8">
                <h1 className="text-richblack-5 text-lg">{card.heading}</h1>

                <p className="text-richblack-300 font-medium">
                  {card.description}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LearningGrid;
