import React, { useEffect, useState } from "react";
import RatingStars from "../../common/RatingStars";
import GetAvgRating from "../../../utils/avgRating";
import { Link } from "react-router-dom";

const CourseCard = ({ course }) => {
  // console.log("coursine in card ",course)
  const [avgReviewCount, setAvgReviewCount] = useState(0);

  useEffect(() => {
    const count = GetAvgRating(course?.ratingAndReviews);
    setAvgReviewCount(count);
  }, [course]);

  return (
    <Link to={`/courses/${course?._id}`}>
      <div className="w-full p-2">
        <div className="bg-richblack-600 rounded-lg shadow-md overflow-hidden course-card">
          <img
            src={course?.thumbnail}
            alt="course thumbnail"
            className="w-full h-64 object-cover transform transition-transform duration-300 hover:scale-105"
          />
          <div className="p-4">
            <p className="text-xl font-semibold text-richblack-5">
              {course?.courseName}
            </p>
            <p className="text-sm text-richblack-50">
              {course?.instructor?.firstName} {course?.instructor?.lastName}
            </p>
            <div className="flex items-center gap-2 text-yellow-100">
              <span>{avgReviewCount || 0}</span>
              <RatingStars Review_Count={avgReviewCount} />
              <span>{course?.ratingAndReviews?.length} Ratings</span>
            </div>
            <p className="text-xl font-semibold text-richblack-5">
              Rs. {course?.price}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
