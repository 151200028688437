import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllAppliedMentors, updateAccountType } from '../../../../../services/operations/profileAPI';
import HighlightText from '../../../HomePage/HighlightText';

const AppliedMentors = () => {
    const { token } = useSelector(state => state.auth);
    const [appliedMentors, setAppliedMentors] = useState([]);

    useEffect(() => {
        const fetchAllMentors = async () => {
            try {
                const res = await getAllAppliedMentors(token);
                setAppliedMentors(res);
            } catch (error) {
                console.error('Error fetching applied mentors:', error);
            }
        };
        fetchAllMentors();
    }, [token]);

    const handleUpdateAccountType = async (mentorId, newAccountType) => {
        try {
            const res=await updateAccountType({mentorId,newAccountType},token)
            console.log("res in update",res);
        } catch (error) {
            console.error('Error updating mentor account type:', error);
        }
    };

    return (
        <Fragment>
            <HighlightText text={"Applied Mentors"} className='mx-auto text-center font-bold text-2xl '/>
            <table className="min-w-full">
                <thead className="text-white">
                    <tr>
                        <th className="px-6 py-3 bg-yellow-20 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Name
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Email
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Account Type
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Update
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {appliedMentors?.map((mentor) => (
                        <tr key={mentor._id} className="transition-all hover:bg-gray-100 shadow-md hover:shadow-lg">
                            <td className="px-6 py-4 whitespace-no-wrap">{mentor.firstName} {mentor.lastName}</td>
                            <td className="px-6 py-4 whitespace-no-wrap">{mentor.email}</td>
                            <td className="px-6 py-4 whitespace-no-wrap">
                                <select
                                    value={mentor.accountType}
                                    onChange={(e) => handleUpdateAccountType(mentor._id, e.target.value)}
                                    className="border-2 border-gray-200 rounded-md py-1 px-2"
                                >
                                    <option value="Student">Student</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Instructor">Instructor</option>
                                </select>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap">
                                <button
                                    onClick={() => handleUpdateAccountType(mentor._id, mentor.accountType)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Update
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Fragment>
    );
};

export default AppliedMentors;
