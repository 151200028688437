import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllForms } from '../../../../../services/operations/profileAPI'; // You need to import your API function to fetch all contact forms

const AllContactForms = () => {
  const { token } = useSelector(state => state.auth);
  const [allForms, setAllForms] = useState([]);

  useEffect(() => {
    const fetchAllContactForms = async () => {
      try {
        const response = await getAllForms(token);
        setAllForms(response);
      } catch (error) {
        console.error('Error fetching contact forms:', error.message);
      }
    };

    fetchAllContactForms();
  }, [token]); 

  return (
    <div className="text-white container mx-auto px-4">
      <h1 className="tex-center text-2xl font-semibold mt-4">All Contact Forms</h1>
      {allForms.map(contact => (
        <div key={contact._id} className="border rounded-lg p-4 my-4">
          <h2 className="text-lg font-semibold mb-2">Contact Information</h2>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <p><span className="font-semibold">ID:</span> {contact._id}</p>
              <p><span className="font-semibold">Email:</span> {contact.email}</p>
              <p><span className="font-semibold">First Name:</span> {contact.firstName}</p>
              <p><span className="font-semibold">Last Name:</span> {contact.lastName}</p>
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0">
              <p><span className="font-semibold">Message:</span> {contact.message}</p>
              <p><span className="font-semibold">Phone No:</span> {contact.phoneNo}</p>
              <p><span className="font-semibold">Country Code:</span> {contact.countryCode}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllContactForms;
