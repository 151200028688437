import React, { Fragment, useEffect, useState } from "react";
import UserTable from "../Dashboard/AdminDashboard/Dashboard/UserTable";
import { getAllEnrolledStudentsInCourse } from "../../../services/operations/profileAPI";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const EnrolledStudentsCourse = () => {
  const [users, setUsers] = useState([]);
  const { courseId } = useParams();
  // console.log("course id is", courseId);
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchEnrolledStudents = async () => {
      const response = await getAllEnrolledStudentsInCourse(courseId, token);
      // console.log("respon", response);
      setUsers(response);
    };
    fetchEnrolledStudents();
  }, []);

  return (
    <Fragment>
      <div className="text-white"> EnrolledStudents </div>
      <UserTable users={users} />
    </Fragment>
  );
};

export default EnrolledStudentsCourse;
