import React, { Fragment, useEffect, useState } from 'react'
import CourseCard from './CourseCard'
import { getAllCourses } from '../../../services/operations/courseDetailsAPI'
import {useDispatch} from "react-redux"

const AllCourses = () => {
    const[allCourses,setAllCourses]=useState([])
    const dispatch=useDispatch()
    useEffect(()=>{
        const allCourses=async()=>{
            const response=await dispatch(getAllCourses())
            // console.log("respon",response);
            setAllCourses(response || [])
        }
        allCourses();
    },[])
  return (
    <Fragment>
        <div className='text-white text-center'>AllCourses</div>
        <div className='flex flex-wrap'>
            {
                allCourses && allCourses.length>0 ? (
                    allCourses.map((course)=>(
                        <CourseCard course={course}/>
                    ))
                ):(
                    <h2>No Courses Found</h2>
                )
            }
        </div>
    </Fragment>
  )
}

export default AllCourses