import React, { useState, useEffect } from "react";
import { apiConnector } from "../../../../services/apiconnector";
import { categories } from "../../../../services/apis";
import { fetchCourseDetails } from "../../../../services/operations/courseDetailsAPI";
import { RiEditBoxLine } from "react-icons/ri";
import CourseCard from "../../Catalog/CourseCard";
import HighlightText from "../../HomePage/HighlightText";
import IconBtn from "../../../common/IconBtn";
import EditCategory from "../Settings/EditCategory";
import Modal from "../../../common/Modal";

const CategoryList = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [courses, setCourses] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await apiConnector("GET", categories.CATEGORIES_API);
        setCategoriesList(res.data.data);
      } catch (error) {
        console.log("Could not fetch Categories.", error);
      }
      setLoading(false);
    })();
  }, []);

  const fetchCoursesByCategory = async (categoryId) => {
    try {
      setLoading(true);
      const categoryCourses = categoriesList.find(
        (cat) => cat._id === categoryId
      )?.courses;

      if (!categoryCourses || categoryCourses.length === 0) {
        console.log("No courses found for this category.");
        setLoading(false);
        return;
      }

      const promises = categoryCourses.map((courseId) =>
        fetchCourseDetails(courseId)
      );
      const allCourseDetails = await Promise.all(promises);

      setCourses(allCourseDetails);
      setSelectedCategory({
        categoryId,
        title: categoriesList.find((cat) => cat._id === categoryId)?.name,
        description: categoriesList.find((cat) => cat._id === categoryId)
          ?.description,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching courses by category:", error);
      setLoading(false);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId === activeCategory ? null : categoryId);
    fetchCoursesByCategory(categoryId);
  };

  const handleEdit = () => {
    setShowEditDialog(true);
  };

  const handleCloseDialog = () => {
    setShowEditDialog(false);
  };

  useEffect(() => {
    console.log("selectedCategory updated:", selectedCategory);
  }, [selectedCategory]);

  return (
    <div className="container mx-auto py-8 text-white">
      <h1 className="text-3xl font-bold mb-4">All Categories</h1>
      <div className="flex flex-col space-x-4 mb-2">
        <div className="width-full">
          <ul className="bg-gray-100 p-4 rounded-lg flex flex-wrap">
            {categoriesList.map((category) => (
              <li key={category._id} className="mb-2 p-4 flex m-5">
                <div
                  className="category-item bg-richblack-700 p-4 rounded-lg shadow-md cursor-pointer transition-transform duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1 hover:bg-richblack-800 flex-grow"
                  onClick={() => handleCategoryClick(category._id)}
                >
                  <h3 className="text-lg font-semibold text-center">
                    <HighlightText text={category.name} />
                  </h3>
                  {activeCategory === category._id && (
                    <p className="text-gray p-2">{category.description}</p>
                  )}
                </div>
                <div className="flex items-center justify-end ml-4">
                  <IconBtn text="Edit" onclick={handleEdit} customClasses="text-richblack-700 bg-yellow-300" outline>
                    <RiEditBoxLine className="text-xl" />
                  </IconBtn>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-2/3">
          {selectedCategory && (
            <div className="bg-richblack-900 p-4 rounded-lg">
              <h1 className="text-3xl font-bold mb-4">
                Courses in{" "}
                {
                  categoriesList.find(
                    (cat) => cat._id === selectedCategory.categoryId
                  )?.name
                }
              </h1>
              <ul>
                {courses?.map((course) => (
                  <CourseCard
                    key={course._id}
                    course={course.data?.courseDetails}
                  />
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <Modal open={showEditDialog} onClose={handleCloseDialog}>
        <EditCategory
          categoryId={selectedCategory?.categoryId}
          cTitle={selectedCategory?.title}
          cDesc={selectedCategory?.description}
        />
      </Modal>
    </div>
  );
};

export default CategoryList;
