import { useState } from "react";
import {useSelector} from "react-redux"
import { updateCategory ,deleteCategory} from "../../../../services/operations/courseDetailsAPI";

export default function EditCategory({ cTitle, cDesc,categoryId }) {
  const [title, setTitle] = useState(cTitle);
  const [description, setDescription] = useState(cDesc);
    const {token}=useSelector(state=>state.auth)

  const handleSubmit = async(e) => {
    e.preventDefault();
    const response=await updateCategory({categoryName:title, categoryDescription:description,categoryId},token)
    console.log("repopnse in edit ",response)
    // setTitle(response.name);
    // setDescription(response.description);
  };

  const handleDelete = async()=>{
    await deleteCategory({categoryId},token)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="my-10 flex flex-col gap-y-6 rounded-md border-[1px] border-richblack-700 bg-richblack-800 p-8 px-12">
          <h2 className="text-lg font-semibold text-richblack-5">
            Category Detals
          </h2>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col">
              <label htmlFor="title" className="lable-style">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id="title"
                placeholder="Enter Title"
                className="form-style"
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="description" className="lable-style">
                Description
              </label>
              <input
                type="text"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                placeholder="Enter Description"
                className="form-style"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <button type="submit" className="cursor-pointer rounded-md bg-yellow-100 py-2 px-5 font-semibold text-white">
            Save
          </button>
          <button type="button" onClick={handleDelete} className="cursor-pointer rounded-md bg-pink-300 py-2 px-5 font-semibold text-white">
            Delete
          </button>
        </div>
      </form>
    </>
  );
}
