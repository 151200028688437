import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, FreeMode, Navigation, Pagination } from "swiper";

import CourseCard from "./CourseCard";
import { getAllCourses } from "../../../services/operations/courseDetailsAPI";

SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const CourseSlider = ({ Courses }) => {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const swiperRef = useRef(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await dispatch(getAllCourses());
        setCourses(response || []);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setCourses([]); // Handle error by setting courses to empty array
      }
    };

    if (!Courses || Courses.length === 0) {
      fetchCourses();
    } else {
      setCourses(Courses); // Set courses if already passed as prop
    }
  }, [Courses]);

  const getSlidesPerView = () => {
    if (window.innerWidth >= 1024) {
      return 3; // Large devices
    } else if (window.innerWidth >= 638) {
      return 2; // Medium devices
    } else {
      return 1; // Small devices
    }
  };

  const slidesPerView = getSlidesPerView();

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.slideNext) {
      swiperRef.current.slideNext();
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.slidePrev) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <div className="mx-auto w-11/12 md:w-full">
      <div className="max-w-screen-lg mx-auto relative">
        {courses?.length ? (
          <Swiper
            ref={swiperRef}
            slidesPerView={slidesPerView}
            spaceBetween={25}
            loop={true}
            modules={[Autoplay, FreeMode, Navigation, Pagination]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            speed={800}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            className="max-h-[30rem] md:max-h-[25rem]"
          >
            {courses.map((course, i) => (
              <SwiperSlide key={i}>
                <CourseCard course={course} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p className="text-xl text-richblack-5">No Course Found</p>
        )}

        <div
          className="swiper-button-next absolute top-1/2 transform -translate-y-1/2 right-4 bg-white rounded-full shadow-md p-2 cursor-pointer z-10 hover:scale-110 transition-transform duration-300"
          onClick={handleNextSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-richblack-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
        <div
          className="swiper-button-prev absolute top-1/2 transform -translate-y-1/2 left-4 bg-white rounded-full shadow-md p-2 cursor-pointer z-10 hover:scale-110 transition-transform duration-300"
          onClick={handlePrevSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-richblack-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CourseSlider;
