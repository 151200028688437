import { createSlice } from "@reduxjs/toolkit";

const storedToken = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : null;
const storedUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const storedUserImage = storedUser ? storedUser.image || "" : "";

const initialState = {
  signupData: null,
  loading: false,
  token: storedToken,
  user: storedUser ? { ...storedUser, image: storedUserImage } : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setSignupData(state, value) {
      state.signupData = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setToken(state, value) {
      state.token = value.payload;
    },
    setUser(state, value) {
      state.user = value.payload;
    },
  },
});

export const { setSignupData, setLoading, setToken, setUser } =
  authSlice.actions;

export default authSlice.reducer;
