import { toast } from "react-hot-toast";

import { setLoading, setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiconnector";
import { profileEndpoints, adminEndPoints, contactusEndpoint } from "../apis";
import { logout } from "./authAPI";
const {
  GET_USER_DETAILS_API,
  GET_USER_ENROLLED_COURSES_API,
  GET_USER_DETAILS_BY_ID_API,
  GET_INSTRUCTOR_DATA_API,
} = profileEndpoints;

const { ALL_CONTACT_FORMS } = contactusEndpoint;
const {
  ALL_USERS_API,
  ALL_ENROLLED_USERS_API,
  ALL_COURSE_ENROLLED_USERS_API,
  ALL_INSTRUCTORS_API,
  ALL_ADMINS_API,
  ALL_APPLIED_MENTORS,
  CHANGE_ACCOUNT_TYPE,
} = adminEndPoints;

export function getUserDetails(token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_USER_DETAILS_API, null, {
        Authorization: `Bearer ${token}`,
      });
      console.log("GET_USER_DETAILS API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      const userImage = response.data.data.image
        ? response.data.data.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.data.firstName} ${response.data.data.lastName}`;
      dispatch(setUser({ ...response.data.data, image: userImage }));
    } catch (error) {
      dispatch(logout(navigate));
      console.log("GET_USER_DETAILS API ERROR............", error);
      toast.error("Could Not Get User Details");
    }
    toast.dismiss(toastId);
    dispatch(setLoading(false));
  };
}

export async function getAllForms(token) {
  try {
    const response = await apiConnector("GET", ALL_CONTACT_FORMS, null, {
      Authorization: `Bearer ${token}`,
    });
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export async function getUserDetailsById(userId, token) {
  try {
    console.log(userId);
    const response = await apiConnector(
      "GET",
      `${GET_USER_DETAILS_BY_ID_API}/${userId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
}

export async function getUserEnrolledCourses(token) {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    console.log("BEFORE Calling BACKEND API FOR ENROLLED COURSES");
    const response = await apiConnector(
      "GET",
      GET_USER_ENROLLED_COURSES_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log("AFTER Calling BACKEND API FOR ENROLLED COURSES");
    // console.log(
    //   "GET_USER_ENROLLED_COURSES_API API RESPONSE............",
    //   response
    // )

    if (!response.data.success) {
      throw new Error(response.data.message);
    }
    result = response.data.data;
  } catch (error) {
    console.log("GET_USER_ENROLLED_COURSES_API API ERROR............", error);
    toast.error("Could Not Get Enrolled Courses");
  }
  toast.dismiss(toastId);
  return result;
}

export async function getInstructorData(token) {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("GET", GET_INSTRUCTOR_DATA_API, null, {
      Authorization: `Bearer ${token}`,
    });

    console.log("GET_INSTRUCTOR_API_RESPONSE", response);
    result = response?.data?.courses;
  } catch (error) {
    console.log("GET_INSTRUCTOR_API ERROR", error);
    toast.error("Could not Get Instructor Data");
  }
  toast.dismiss(toastId);
  return result;
}

//Admins
export async function getAllUsers(token) {
  try {
    const response = await apiConnector("GET", ALL_USERS_API, null, {
      Authorization: `Bearer ${token}`,
    });
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred");
    console.log("Error while fetching users:", error.message);
  }
}

export async function getAllInstructors(token) {
  try {
    const response = await apiConnector("GET", ALL_INSTRUCTORS_API, null, {
      Authorization: `Bearer ${token}`,
    });
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred");
    console.log("Error while fetching Instructors:", error.message);
  }
}

export async function getAllAdmins(token) {
  try {
    const response = await apiConnector("GET", ALL_ADMINS_API, null, {
      Authorization: `Bearer ${token}`,
    });
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred");
    console.log("Error while fetching admins:", error.message);
  }
}

export async function getAllEnrolledStudents(token) {
  try {
    const response = await apiConnector("GET", ALL_ENROLLED_USERS_API, null, {
      Authorization: `Bearer ${token}`,
    });
    console.log("log", response);
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred");
    console.log("Error while fetching enrolled students:", error.message);
  }
}

export async function getAllEnrolledStudentsInCourse(courseId, token) {
  try {
    console.log(courseId);
    const response = await apiConnector(
      "GET",
      `${ALL_COURSE_ENROLLED_USERS_API}/${courseId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log("response", response);
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "An error occurred");
    console.log("Error while fetching enrolled students:", error.message);
  }
}

export async function getAllAppliedMentors(token) {
  try {
    const response = await apiConnector("GET", ALL_APPLIED_MENTORS, null, {
      Authorization: `Bearer ${token}`,
    });
    console.log("ALL_APPLIED_MENTORS response", response);
    // toast.success(response.data.message)
    return response.data.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message || "An error occurred");
    console.log("Error while fetching all Applied mentors:", error.message);
  }
}

export async function updateAccountType(data, token) {
  try {
    const res = await apiConnector(
      "PUT",
      CHANGE_ACCOUNT_TYPE,
      { userId: data.mentorId, accountType: data.newAccountType },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log(res);
    toast.success(res?.data?.message);
    // return res.data.message;
  } catch (error) {
    console.log("Error while updating AccountType");
    toast.error(error.response.data.message);
  }
}
