
import React, { useEffect, useState } from "react";
// import { HomePageExplore } from "../../../data/homepage-explore";
// import CourseCard from "./CourseCard";
import HighlightText from "./HighlightText";
import { getCoursesByTags } from "../../../services/operations/courseDetailsAPI";
import CourseSlider from "../Catalog/CourseSlider";
import {useSelector } from "react-redux"

const tabsName = [
  "Free",
  "New to coding",
  "Most popular",
  "Skills paths",
  "Career paths",
];

const ExploreMore = () => {
  const [currentTab, setCurrentTab] = useState(tabsName[0]);
  const [courses, setCourses] = useState([]);
  const [currentCard, setCurrentCard] = useState("");
  const {token}=useSelector(state=>state.auth)

  useEffect(() => {
    const fetchCoursesTags = async () => {
      // console.log("current tab is ", currentTab);
      const response = await getCoursesByTags([currentTab],token);
      // console.log("coursei s", response);
      setCourses(response);
      setCurrentCard(response[0]?.courseName);
    };
    fetchCoursesTags();
  }, [currentTab]);

  const setMyCards = (value) => {
    setCurrentTab(value);
    // const result = HomePageExplore.filter((course) => course.tag === value);
    // setCourses(result[0].courses);
    // setCurrentCard(result[0].courses[0].heading);
  };

  return (
    <div>
      {/* Explore more section */}
      <div>
        <div className="text-4xl font-semibold text-center my-10">
          Unlock the
          <HighlightText text={"Power of Code"} />
          <p className="text-center text-richblack-300 text-lg font-semibold mt-1">
            Learn to Build Anything You Can Imagine
          </p>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="align-center justify-center lg:flex gap-5 -mt-5 mx-auto w-max bg-richblack-800 text-richblack-200 p-1 rounded-full font-medium drop-shadow-[0_1.5px_rgba(255,255,255,0.25)]">
        {tabsName.map((ele, index) => {
          return (
            <div
              className={`text-[16px] flex flex-row items-center gap-2 ${
                currentTab === ele
                  ? "bg-richblack-900 text-richblack-5 font-medium"
                  : "text-richblack-200"
              } px-7 py-[7px] rounded-full transition-all duration-200 cursor-pointer hover:bg-richblack-900 hover:text-richblack-5`}
              key={index}
              onClick={() => setMyCards(ele)}
            >
              {ele}
            </div>
          );
        })}
      </div>
      <div className="hidden lg:block lg:h-[200px]"></div>

      {/* Cards Group */}
      <div className="lg:absolute gap-10 justify-center lg:gap-0 flex lg:justify-between flex-wrap lg:bottom-[0] lg:left-[50%] lg:translate-x-[-50%] lg:translate-y-[50%] text-black lg:mb-0 mb-7 lg:px-0 px-3 max-w-[80vw] sm:m-auto mt-10 pt-5">
        <CourseSlider Courses={courses} />
      </div>
    </div>
  );
};

export default ExploreMore;
