import React, { useState } from 'react';
import {useSelector ,useDispatch} from "react-redux"
import { createCategory } from '../../../../services/operations/courseDetailsAPI';

const CreateCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const dispatch=useDispatch();
  const {token} = useSelector((state)=>state.auth)

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setCategoryDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCategory({categoryName,categoryDescription},token))
    // console.log('Category Name:', categoryName);
    // console.log('Category Description:', categoryDescription);

    // Reset the form fields after submission
    setCategoryName('');
    setCategoryDescription('');
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <form onSubmit={handleSubmit} className="border-richblack-700 bg-richblack-800 shadow-md rounded px-8 pt-6 pb-8 mb-4 text-white">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="categoryName">
            Category Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-yellow-100 leading-tight focus:outline-none focus:shadow-outline bg-richblack-500"
            id="categoryName"
            type="text"
            placeholder="Enter category name"
            value={categoryName}
            onChange={handleNameChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2" htmlFor="categoryDescription">
            Category Description
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-yellow-100 leading-tight focus:outline-none focus:shadow-outline bg-richblack-500"
            id="categoryDescription"
            placeholder="Enter category description"
            value={categoryDescription}
            onChange={handleDescriptionChange}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-yellow-50 hover:bg-yellow-25 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Create Category
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCategory;
